import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";

// Import translations.
import common_en from './translations/en/common.json';
import common_es from './translations/es/common.json';
import common_ca from './translations/ca/common.json';

const supported_languages = ['en', 'es', 'ca'];
const navigator_language = (navigator.language || navigator.userLanguage || 'en').split('-')[0];
const language = supported_languages.includes(navigator_language) ? navigator_language : 'en';

// Set language attribute to html tag.
document.querySelector('html').setAttribute('lang', language);

i18next
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    resources: {
      en: {
        translation: common_en
      },
      es: {
        translation: common_es
      },
      ca: {
        translation: common_ca
      },
    },
    lng: navigator.language || navigator.userLanguage || 'en',
    fallbackLng: 'en',
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </I18nextProvider>
</React.StrictMode>);
