import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import whatsapp_icon from "../images/whatsapp_icon.png";
import calculations from "../calculations";

// TODO Extract to a helper.
function getFormattedDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
}

const generateBar = (correct, total, score) => {
  const bar = [];
  for (let i = 0; i < total; i++) {
    bar.push(i < correct ? (score[i] <= 5 ? "🟩" : "🟨") : (i === correct ? "🟥" : "⬜"));
  }
  return bar.join("");
}

export default function Summary(props) {

  const { history } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const shareRef = useRef();

  const score = history[getFormattedDate()];
  const operations = calculations.getDaily(getFormattedDate());

  useEffect(() => {
    if (score === undefined) {
      navigate("/");
    }
  }, [history]);

  if (score === undefined) {
    return null;
  }

  const correct = score.length;
  const total = 10;
  const bar = generateBar(correct, total, score);
  const time = score.reduce((a, b) => a + b, 0);
  const failed_operation = operations[correct] ?? null;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert(t('DAILY_COPIED_TO_CLIPBOARD')))
      .catch(() => alert('Failed to copy to clipboard!'));
  }

  return (<div className="section section-summary">
    <div className="daily-result">
      <div ref={shareRef} onClick={() => {
        if (failed_operation) {
          document.querySelector("dialog").showModal();
        }
      }}>
        {t('DAILY_SHARE_TITLE')} <br/>
        {bar} <br/>
        🎯 {correct}/{total} {t('DAILY_SHARE_IN')} 🕒 {time}s
      </div>
    </div>
    <div className={"share"}>
      <div>{t('DAILY_SHARE_ACTIONS_TITLE')}</div>
      <div className={"actions"}>
        <a className="button retry" onClick={() => {
          copyToClipboard(shareRef.current.innerText + "\n\nhttps://numynums.com");
        }} title={t('DAILY_SHARE_CLIPBOARD')}>
          📋
        </a>
        <a className="button retry"
           onClick={() => document.location = `https://wa.me/?text=${encodeURI(shareRef.current.innerText + "\n\nhttps://numynums.com")}`}
           title={t('DAILY_SHARE_WHATSAPP')}>
          <img className={"icon"} src={whatsapp_icon} alt={t("DAILY_SHARE_WHATSAPP")}/>
        </a>
      </div>
    </div>
    <a className="button retry" onClick={() => navigate("/")}>
      {t('HOME')}
    </a>
    <footer>
      <p>{t('FOOTER')} ❤️</p>
    </footer>
    <dialog>
      <div>
        <button autoFocus onClick={() => document.querySelector("dialog").close()}> X</button>
        <p>{t('DAILY_SHARE_FAILURE_TEXT')}</p>
        <p>{calculations.format(failed_operation) + " = " + calculations.solve(failed_operation)}</p>
      </div>
    </dialog>
  </div>);
}
