import {useTranslation} from "react-i18next";
import 'animate.css';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import whatsapp_icon from "../images/whatsapp_icon.png";

export default function Main(props) {

  const { audios } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Dirty preloading of whatsapp icon.
    const img = new Image();
    img.src = whatsapp_icon;

    // Fun l33t effect.
    const interval = setInterval(() => {
      console.log("Running");
      const leet_map = {
        'a': '4',
        'e': '3',
        'i': '1',
        'o': '0',
        's': '5',
        't': '7',
        '4': 'a',
        '3': 'e',
        '1': 'i',
        '0': 'o',
        '5': 's',
        '7': 't',
      };
      const introText = document.querySelector('.intro-text');
      const text = introText.textContent;
      introText.textContent = text.split('').map((c) => Math.random() > 0.90 ? (leet_map[c] || c) : c).join('');
      introText.classList.add('animate__shakeX', 'animate__faster');
      introText.addEventListener('animationend', () => {
        introText.classList.remove('animate__shakeX', 'animate__faster');
      }, { once: true });

    }, 5000);

    return () => clearInterval(interval); // Clear interval on unmount.
  }, []);

  const playPractice = () => {
    audios.start.play();
    navigate('/play');
  }

  const playDaily = () => {
    audios.start.play();
    navigate('/daily');
  }


  return (<div className="section section-main">
    <header>
      <h1 className="brand-heading"><span className="light">numy</span>nums</h1>
      <p className="intro-text animate__animated">{t('SUBHEADER')}</p>
    </header>
    <button aria-label={t('DAILY_CHALLENGE')} onClick={playDaily} className="button animate__animated animate__flipInX">
      {t('DAILY_CHALLENGE')}
    </button>
    <button aria-label={t('PRACTICE')} onClick={playPractice} className="button animate__animated animate__flipInX">
      {t('PRACTICE')}
    </button>
    <footer>
      <p>{t('FOOTER')} ❤️</p>
    </footer>
  </div>);
}
