import './App.css';
import {Routes, Route} from 'react-router-dom';
import Main from './sections/Main';
import Play from './sections/Play';
import Summary from './sections/Summary';
import Daily from './sections/Daily';
import DailySummary from './sections/DailySummary';
import Reset from './sections/Reset';
import {useEffect, useState} from "react";

const audios = {
  success: new Audio('/sounds/success.mp3'),
  click: new Audio('/sounds/click.mp3'),
  start: new Audio('/sounds/start.mp3'),
  fail: new Audio('/sounds/fail.mp3')
}
// Lower volume for all audios in a loop.
for (let audio in audios) {
  audios[audio].volume = 0.5;
  audios[audio].preload = 'auto'; // Preoloads audio as soon as possible.
}

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default function App() {

  const [score, setScore] = useState(0);
  const [history, setHistory] = useLocalStorage('history', {});

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main audios={audios}/>}/>
        <Route path="/play" element={<Play setScore={setScore} score={score} audios={audios}/>}/>
        <Route path="/summary" element={<Summary score={score} audios={audios}/>}/>
        <Route path="/daily" element={<Daily setHistory={setHistory} history={history} audios={audios}/>}/>
        <Route path="/daily/summary" element={<DailySummary history={history} audios={audios}/>}/>
        <Route path="/reset" element={<Reset/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h1>Not Found</h1>
    </div>
  );
}
