import {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import calculations from "../calculations";

const play = (sound) => {
  sound.currentTime = 0; // Seek to the start.
  const promise = sound.play();
  if (promise !== undefined) {
    promise.catch(error => {
      // This is triggered if `pause()` is called. Ignoring on purpose.
    });
  }
}

const TIMER = 10;

function getFormattedDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
}


export default function Daily(props) {


  const { audios, history, setHistory } = props;

  const operations = useMemo(() => calculations.getDaily(getFormattedDate()), []);
  const navigate = useNavigate();
  const [score, setScore] = useState([]);
  const [allowed, setAllowed] = useState(true);
  const [result, setResult] = useState("");
  const [currentOperation, setCurrentOperation] = useState(0);
  const [operation, setOperation] = useState(operations[currentOperation]);
  const [counter, setCounter] = useState(TIMER);


  // Run when initially mounted.
  useEffect(() => {

    // Restart timer.
    restartTimer();

    // Redirect to summary if the daily challenge has already been completed.
    if (history[getFormattedDate()] !== undefined) {
      navigate("/daily/summary");
    }
  }, []);

  // Navigate to summary if the daily challenge has been completed.
  useEffect(() => {
    if (history[getFormattedDate()] !== undefined) {
      navigate("/daily/summary");
    }
  }, [history]);

  useEffect(() => {
    evaluate(result);
  }, [result]);

  useEffect(() => {
    if (counter === 0) {
      fail();
    }
  }, [counter]);

  const timerId = useRef();

  const decrement = () => {
    setCounter(prevCounter => prevCounter - 1);
  };

  // Similar to $interval.cancel
  const stopTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
      timerId.current = null;
    }
  };

  // Similar to $interval
  const restartTimer = () => {
    setCounter(TIMER);
    stopTimer();
    timerId.current = setInterval(decrement, 1000);
  };

  const hype = async (ms, method) => {
    setTimeout(method, ms);
  }

  const success = () => {
    // Play sound.
    audios.click.pause();
    play(audios.success);

    // Update score by adding the time it took to solve it.
    const newScore = [...score, TIMER - counter];
    setScore(newScore);
    if (newScore.length === operations.length) {
      // Record the score in the history.
      setHistory(prevHistory => {
        return {
          ...prevHistory, [getFormattedDate()]: newScore
        };
      });
    }

    restart();
  };

  const fail = () => {
    audios.click.pause();
    play(audios.fail);
    stopTimer();
    // Record the score in the history.
    setHistory(prevHistory => {
      return {
        ...prevHistory, [getFormattedDate()]: score
      };
    });
  };

  const evaluate = () => {
    // Block input until we evaluate the result.
    setAllowed(false);

    const remaining = calculations.validate(operation, result);
    if (remaining === 0) {
      hype(300, () => {
        success();
        // Unblock input.
        setAllowed(true);
      });
    } else if (remaining < 0) {
      hype(800, () => {
        fail();
        // Unblock input.
        setAllowed(true);
      });
    } else {
      // Unblock input.
      setAllowed(true);
    }
  };

  const onPress = (value) => {
    return () => {
      if (allowed) {
        play(audios.click);
        setResult(prevResult => {
          return `${prevResult}${value}`;
        });
      }
    }
  };

  const restart = () => {
    setResult("");
    const nextOperation = currentOperation + 1;
    if (nextOperation === operations.length) {
      // Early return, hacky way to navigate to summary when the last operation is reached while having state updated.
      return;
    }
    setCurrentOperation(nextOperation);
    const newOperation = operations[nextOperation]; // TODO Fix overflow.
    setOperation(newOperation);
    restartTimer();
  };


  return (<div className="section section-play">
    <div className="score-wrapper">
      <div id="score-notice" className="score-notice hidden">
        +{counter}
      </div>
      <div className="score">
        {score.length}
      </div>
    </div>
    <div className="operation" tabIndex={0}>
      <div className="operand-left">{calculations.format(operation)}</div>
      <div className="operand-center">{"="}</div>
      <div className="operand-right">{result}</div>
    </div>
    <div className="wrapper">
      <div id="timer">
        <div id="percentage" style={{ width: `${counter * 10}%` }}><span id="counter">+{counter}</span></div>
      </div>
      <div className="numpad animate__animated animate__zoomIn">
        <button onClick={onPress('1')} className="button">
          <div>1</div>
        </button>
        <button onClick={onPress('2')} className="button">
          <div>2</div>
        </button>
        <button onClick={onPress('3')} className="button">
          <div>3</div>
        </button>
        <button onClick={onPress('4')} className="button">
          <div>4</div>
        </button>
        <button onClick={onPress('5')} className="button">
          <div>5</div>
        </button>
        <button onClick={onPress('6')} className="button">
          <div>6</div>
        </button>
        <button onClick={onPress('7')} className="button">
          <div>7</div>
        </button>
        <button onClick={onPress('8')} className="button">
          <div>8</div>
        </button>
        <button onClick={onPress('9')} className="button">
          <div>9</div>
        </button>
        <a className="button">
          <div>&nbsp;</div>
        </a>
        <button onClick={onPress('0')} className="button">
          <div>0</div>
        </button>
        <button onClick={onPress('-')} className="button">
          <div>-</div>
        </button>
      </div>
    </div>
  </div>);
}
