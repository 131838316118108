import seedrandom from 'seedrandom';

let rng = seedrandom();

const randomInt = (min, max) => {
  const random_value = rng();
  return Math.floor(random_value * (max - min + 1)) + min;
}

const calculations = {
  solve: (operation) => {
    return eval(operation);
  },
  validate: (operation, value) => {
    var expectedAsString = "" + eval(operation);
    var valueAsString = "" + value;
    var i = 0;
    for (i = 0; i < valueAsString.length; i++) {
      if (valueAsString.charAt(i) !== expectedAsString.charAt(i)) {
        return -1;
      }
    }
    return expectedAsString.length - i;
  },
  format: (operation) => {
    return operation.replaceAll("*", "x");
  },
  get: (score) => {
    var calculation = "";
    var numbers = 2;
    if (score >= 100) {
      numbers = 3;
    }
    // Division removed to avoid decimals
    var operations = ["*", "+", "-"];
    var max = 10 + Math.floor(score / 20);
    var min = Math.floor(score / 20);
    for (var i = 0; i < numbers; i++) {
      calculation += randomInt(min, max);
      calculation += "" + operations[randomInt(0, operations.length - 1)]
    }
    return calculation.substring(0, calculation.length - 1);
  },
  getDaily: (day) => {
    const seed = `${day}`;
    rng = seedrandom(seed);
    const operations = [];
    for (let i = 0; i < 10; i++) {
      const operation = calculations.get(i * 20);
      operations.push(operation);
    }
    rng = seedrandom(); // TODO Fix this!
    return operations;
  }
}

export default calculations;
