import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function Reset() {

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('history', '{}');
    navigate('/');
  }, []);


  return (<>Resetting</>);
}
