import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function Summary(props) {

  const { score, audios } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const best = localStorage.getItem('best');
  const phrase_tone = (parseInt(score) === parseInt(best)) ? 'PROUD' : 'SHAME';
  const phrase_key = `PHRASE_${phrase_tone}_${Math.floor(Math.random() * 10)}`;

  const playAgain = () => {
    audios.start.play();
    navigate('/play');
  }

  return (<div className="section section-summary">
    <div className="results">
      <h1 className="brand-heading">{t('CURRENT')}: {score}</h1>
      <h1 className="brand-heading">{t('BEST')}: {best}</h1>
      <p className="intro-text">{t(phrase_key)}</p>
    </div>
    <a className="button retry" onClick={playAgain}>
      {t('RETRY')}
    </a>
    <a className="button retry" onClick={() => navigate("/")}>
      {t('HOME')}
    </a>
    <footer>
      <p>{t('FOOTER')} ❤️</p>
    </footer>
  </div>);
}
