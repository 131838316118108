import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import 'animate.css';
import calculations from "../calculations";

const play = (sound) => {
  sound.currentTime = 0; // Seek to the start.
  const promise = sound.play();
  if (promise !== undefined) {
    promise.catch(error => {
      // This is triggered if `pause()` is called. Ignoring on purpose.
    });
  }
}

export default function Play(props) {

  const { setScore, score, audios } = props;

  const [allowed, setAllowed] = useState(true);
  // Load best score from local storage.
  const [bestScore, setBestScore] = useState(localStorage.getItem('best') || 0);
  const [result, setResult] = useState("");
  const [operation, setOperation] = useState("");
  const [counter, setCounter] = useState(10);

  const navigate = useNavigate();

  const timerId = useRef();

  const decrement = () => {
    setCounter(prevCounter => {
      const newCounter = prevCounter - 1;
      if (newCounter <= 0) {
        fail();
      }
      return newCounter;
    });
  };

  // Similar to $interval.cancel
  const stopTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
      timerId.current = null;
    }
  };

  // Similar to $interval
  const restartTimer = () => {
    setCounter(10);
    stopTimer();
    timerId.current = setInterval(decrement, 1000);
  };

  const hype = async (ms, method) => {
    setTimeout(method, ms);
  }

  const success = () => {
    // Play sound.
    audios.click.pause();
    play(audios.success);

    // Animate score notice.
    const counter_element = document.getElementById("score-notice");
    counter_element.classList.remove("hidden"); // First show the element.
    counter_element.classList.add("animate__animated", "animate__fadeOutRight");
    counter_element.addEventListener("animationend", () => {
      counter_element.classList.add("hidden"); // Hide element again.
      counter_element.classList.remove("animate__animated", "animate__fadeOutRight");
    });

    // Update score.
    const newScore = score + counter;
    setScore(newScore);
    if (newScore > bestScore) {
      setBestScore(newScore);
    }
    restart();
  };

  const fail = () => {
    audios.click.pause();
    play(audios.fail);
    stopTimer();
    navigate("/summary");
  };

  const evaluate = () => {
    // Block input until we evaluate the result.
    setAllowed(false);

    const remaining = calculations.validate(operation, result);
    if (remaining === 0) {
      hype(300, () => {
        success();
        // Unblock input.
        setAllowed(true);
      });
    } else if (remaining < 0) {
      hype(800, () => {
        fail();
        // Unblock input.
        setAllowed(true);
      });
    } else {
      // Unblock input.
      setAllowed(true);
    }
  };

  const onPress = (value) => {
    return () => {
      if (allowed) {
        play(audios.click);
        setResult(prevResult => {
          return `${prevResult}${value}`;
        });
      }
    }
  };

  const restart = () => {
    setResult("");
    const newOperation = calculations.get(score);
    setOperation(newOperation);
    restartTimer();
  };

  useEffect(() => {
    setScore(0);
    restart(); // ComponentDidMount equivalent
    return () => {
      stopTimer(); // ComponentWillUnmount equivalent
    };
  }, []);

  useEffect(() => {
    evaluate(result);
  }, [result]);

  useEffect(() => {
    localStorage.setItem('best', bestScore);
  }, [bestScore]);

  return (
    <div className="section section-play">
      <div className="score-wrapper">
        <div id="score-notice" className="score-notice hidden">
          +{counter}
        </div>
        <div className="score">
          {score}
        </div>
      </div>
      <div className="operation">
        <div className="operand-left" tabIndex={0} aria-live="polite">{calculations.format(operation)}</div>
        <div className="operand-center">{"="}</div>
        <div className="operand-right">{result}</div>
      </div>
      <div className="wrapper">
        <div id="timer">
          <div id="percentage" style={{ width: `${counter * 10}%` }}><span id="counter">+{counter}</span></div>
        </div>
        <div className="numpad animate__animated animate__zoomIn">
          <button onClick={onPress('1')} className="button">
            <div>1</div>
          </button>
          <button onClick={onPress('2')} className="button">
            <div>2</div>
          </button>
          <button onClick={onPress('3')} className="button">
            <div>3</div>
          </button>
          <button onClick={onPress('4')} className="button">
            <div>4</div>
          </button>
          <button onClick={onPress('5')} className="button">
            <div>5</div>
          </button>
          <button onClick={onPress('6')} className="button">
            <div>6</div>
          </button>
          <button onClick={onPress('7')} className="button">
            <div>7</div>
          </button>
          <button onClick={onPress('8')} className="button">
            <div>8</div>
          </button>
          <button onClick={onPress('9')} className="button">
            <div>9</div>
          </button>
          <a className="button">
            <div>&nbsp;</div>
          </a>
          <button onClick={onPress('0')} className="button">
            <div>0</div>
          </button>
          <button onClick={onPress('-')} className="button">
            <div>-</div>
          </button>
        </div>
      </div>
    </div>
  )
    ;
}
